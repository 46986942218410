import Head from "@components/Head";
import React from "react";
import Hero from "./components/Hero-crypto";
import FraudulentCheck from "./components/FraudulentCheck";
import Videos from "./components/Videos-crypto";
import Articles from "./components/Articles";
import Pillar from "./components/Pillar";
import Forum from "./components/Forum";
import CTASection from "./components/CTASection";
import OfficialPartners from "./components/OfficialPartners";
const TransparancyCrypto = () => {
  return <div className='reku-new-theme'>
            <Head title='Fondasi Kenyamanan & Keamanan Crypto di Reku' description='Reku menjamin keamanan dan kenyamanan jual beli kripto untuk pengguna. Kenalan dengan aset kripto lebih transparan di sini!' titleSuffix='' disableSeoConfig={true} />
            <Hero />
            <FraudulentCheck />
            <Videos />
            <Articles />
            <Pillar />
            <Forum />
            <CTASection />
            <OfficialPartners />
        </div>;
};
export default TransparancyCrypto;