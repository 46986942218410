import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLanguage } from "@config/i18n";
import TransparancyCrypto from "@modules/Transparancy/TransparancyCrypto";
export const getStaticProps = async ({
  locale
}: {
  locale: string;
}) => ({
  props: {
    ...(await serverSideTranslations(locale || defaultLanguage, ["components", "pages", "common", "transparancy"]))
  }
});
export default TransparancyCrypto;